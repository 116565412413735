<template>
    <div class="profile">
        <v-toolbar color="primary" dark flat>
            <v-btn icon @click="goBack">
                <v-icon large>arrow_back</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card flat v-if="user">
            <v-card-text>
                <div class="text-center">
                    <v-avatar color="primary" size="36" class="mb-3">
                        <span
                            class="white--text">{{ user.firstName.substring(0, 1) }}{{
                                user.lastName.substring(0, 1)
                            }}</span>
                    </v-avatar>
                    <div class="text-center">
                        <p>
                            {{ $t("profile.hi", { firstName: user.firstName }) }}
                            <br />
                            {{ $t("profile.update_settings") }}
                        </p>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <div class="pa-3" v-if="user">
            <v-card flat v-if="languages.length > 1">
                <v-card-text>
                    <h2>{{ $t("profile.change_language") }}</h2>
                    <v-select @change="changeLocale($event)" :items="languages" :value="$i18n.locale" item-value="key"
                    >
                        <template slot="item" slot-scope="data">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <flag :iso="data.item.flag" :squared="false" />
                                    - {{ data.item.label }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <template slot="selection" slot-scope="data">
                            <flag :iso="data.item.flag" :squared="false" />
                            - {{ data.item.label }}
                        </template>
                    </v-select>
                </v-card-text>
            </v-card>
            <v-card flat v-if="!isOffline">
                <v-card-text>
                    <form>
                        <!--<div class="mb-6">
                            <h2>User information</h2>
                            <div class="mb-3">
                                <v-text-field label="First name"
                                              type="text"
                                              v-model="user.firstName"
                                ></v-text-field>
                                <v-text-field label="Last name"
                                              type="text"
                                              v-model="user.lastName"
                                ></v-text-field>
                                <v-text-field label="Email"
                                              type="email"
                                              v-model="user.email"
                                ></v-text-field>
                                <v-text-field label="Job title"
                                              type="text"
                                              v-model="user.jobTitle"
                                ></v-text-field>
                            </div>
                            <div class="mb-3">
                                <v-btn color="primary" rounded outlined block @click="updateUserProfile">Update
                                    profile
                                </v-btn>
                            </div>
                        </div>-->
                        <div class="mb-3">
                            <h2>{{ $t("profile.update_password") }}</h2>
                            <div class="mb-3">
                                <v-text-field :label="$t('password.current')"
                                              type="password"
                                              v-model="oldPassword"
                                ></v-text-field>
                                <v-text-field :label="$t('password.new')"
                                              type="password"
                                              v-model="newPassword"
                                              :hint="$t('password.hint')"
                                              :rules="[rules.required, rules.min]"
                                ></v-text-field>
                                <v-text-field
                                    type="password"
                                    v-model="passwordConfirm"
                                    :hint="$t('password.hint')"
                                    :rules="[rules.required, rules.min, passwordConfirmationRule]"
                                    :label="$t('password.label_confirm')"
                                ></v-text-field>
                            </div>
                            <div>
                                <v-btn color="primary" rounded outlined :loading="isUpdatingPassword" block
                                       @click="updatePassword">
                                    {{ $t("profile.update_password") }}
                                </v-btn>
                            </div>
                        </div>
                        <div class="mt-3 mb-3 text-center">
                            <div class="mx-auto">
                                <v-btn color="red" text @click="showDeleteConfirmation">{{ $t("profile.delete") }}
                                </v-btn>
                            </div>
                        </div>
                    </form>
                </v-card-text>
            </v-card>
        </div>

        <v-card flat v-if="isOffline">
            <v-alert type="warning" dense prominent>
                {{ $t("profile.offline_mode") }}

            </v-alert>
        </v-card>

        <v-dialog v-if="showConfirmDeleteUser" v-model="showConfirmDeleteUser" width="290">
            <v-card>
                <v-card-title>{{ $t("profile.delete_modal.title") }}</v-card-title>
                <v-card-text>
                    <p>{{ $t("profile.delete_modal.body") }}</p>
                    <div class="mt-3">
                        <v-btn color="primary" rounded outlined block @click="showConfirmDeleteUser = false">
                            {{ $t("basic.cancel") }}
                        </v-btn>
                        <v-btn class="mt-3" color="red" block text @click="deleteProfile">{{ $t("profile.delete") }}
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar color="primary" :bottom="y === 'bottom'" v-model="isSaved">
            {{ $t("profile.saved") }}
            <v-btn color="white" text @click="isSaved = false">
                {{ $t("basic.close") }}
            </v-btn>
        </v-snackbar>

        <v-snackbar color="error" :bottom="y === 'bottom'" v-model="isError">
            {{ $t(errorMessage) }}
            <v-btn color="white" text @click="isError = false">
                {{ $t("basic.close") }}
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'Profile',
    data: function() {
        const $t = this.$t.bind(this)
        return {
            rules: {
                required: value => !!value || $t('fields.general.rules.required'),
                min: v => (v && v.length >= 8) || $t('fields.general.rules.min', {min: 8})
            },
            oldPassword: '',
            newPassword: '',
            user: null,
            isUpdatingPassword: false,
            // isUpdatingUser: false,
            showConfirmDeleteUser: false,
            isSaved: false,
            isError: false,
            timeout: 6000,
            y: 'top',
            isOffline: false,
            passwordConfirm: '',
            errorMessage: 'profile.error'
        };
    },
    computed: {
        ...mapState('users', ['currentUser']),
        ...mapState('client', ['currentClient']),
        passwordConfirmationRule() {
            const $t = this.$t.bind(this)
            return () => (this.newPassword === this.passwordConfirm) ||$t('fields.general.rules.must_match', {field: 'password'});
        },
        languages() {
            return [
                {
                    key: 'en',
                    label: 'English',
                    flag: 'us'
                },
                {
                    key: 'da',
                    label: 'Danish',
                    flag: 'dk'
                },
                {
                    key: 'ru',
                    label: 'Russian',
                    flag: 'ru'
                }
            ];
        }

    },
    methods: {
        goBack() {
            this.$router.back();
        },
        updatePassword() {
            this.errorMessage = 'profile.error';
            if (!this.newPassword || !this.oldPassword) {
                this.isError = true;
                this.errorMessage = 'profile.error_password_empty';
                return;
            }
            this.isUpdatingPassword = true;
            const payload = {
                email: this.currentUser.email,
                oldPassword: this.oldPassword,
                newPassword: this.newPassword
            };
            this.$store.dispatch('users/onUpdatePassword', payload).then((response) => {
                this.isUpdatingPassword = false;
                this.isSaved = true;
                this.isError = false;
            }).catch(() => {
                this.isLoading = false;
                this.isError = true;
            });
        },
        showDeleteConfirmation() {
            this.showConfirmDeleteUser = true;
        },
        deleteProfile() {
            this.$store.dispatch('users/onDeleteUserAccount', this.currentUser.id).then(() => {
                this.isSaved = true;
                this.$router.to('/');
            }).catch(() => {
                this.isError = true;
            });
        },
        changeLocale(locale) {
            this.$i18n.locale = locale;
            window.localStorage.setItem('locale', locale);
            window.locale = locale;
            if (navigator?.onLine) {
                this.$store.dispatch('users/onUpdateLocale', locale).then(() => {
                    this.$i18n.locale = locale;
                });
            }
        }
    },
    created() {
        if (this.isOnline) {
            this.user = Object.assign({}, this.currentUser);
        }
        if (!this.isOnline) {
            this.isOffline = true;
        }
        this.$store.dispatch('client/getCurrentClient');
    }
};
</script>

<style scoped lang="scss">
.flags {
    display: flex;
    justify-content: space-evenly;

    .flag {
        padding: 1rem;

        &.selected {

        }
    }

}
</style>
